import Axios from 'axios';
import React, { FC, useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { FaCalendarAlt, FaChevronDown, FaEllipsisH, FaEnvelope, FaFileInvoice, FaHandsHelping, FaNewspaper, FaProjectDiagram, FaSitemap, FaTachometerAlt, FaTree, FaUser, FaUsers } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { Badge, Breadcrumb, BreadcrumbItem, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Row, Spinner, Toast, ToastBody, ToastHeader, UncontrolledDropdown } from 'reactstrap';
import '../../assets/scss/dashboard-layout.scss';
import { useToastContext } from '../../contexts/ToastContext';
import LoadingNav from '../components/LoadingComponents/LoadingNav';
import DashboardMenuItem, { MenuItem } from '../components/navigation/DashboardMenuItem';

const DashboardLayout: FC = props => {
    const addToast = useToastContext();
    const history = useHistory();
    const getUser = useAsync(() => Axios.get("/api/user"), []);
    const logout = useAsyncCallback(
        () => Axios.post("/api/user/logout")
            .then(response => { history.push("/"); localStorage.removeItem("imlk_token"); })
            .catch(e => console.error(e))
    );
    const [activeMenuItem, setActiveMenuItem] = useState(history.location.pathname);

    const ths_auth = localStorage.getItem("imlk_token");
    if (!ths_auth || (!getUser.loading && (getUser.error || !getUser.result))) {
        setTimeout(() => {
            history.push("/", { login: true, register: false });
        }, 5000);
        return <div className="h-100 d-flex justify-content-center">
            <div>
                <Toast icon={<Spinner size="sm" />}>
                    <ToastHeader>
                        Authentication failed
                </ToastHeader>
                    <ToastBody>
                        You are not signed in. You will automatically be redirected to the Landing Page in a few seconds!
                </ToastBody>
                </Toast>
            </div>
        </div>
    } else {
        const userData = JSON.parse(atob((ths_auth || " . ").split('.')[1].replace('-', '+').replace('_', '/')));
        if (!userData.active) {
            setTimeout(() => {
                history.push("/", { login: true, register: false });
            }, 5000);
            return <div className="h-100 d-flex justify-content-center">
                <div>
                    <Toast icon={<Spinner size="sm" />}>
                        <ToastHeader>
                            Please confirm your email {userData.email}
                        </ToastHeader>
                        <ToastBody>
                            Your email {userData.email} hasn't been verified yet. You will automatically be redirected to the Landing Page in a few seconds!
                    </ToastBody>
                    </Toast>
                </div>
            </div>
        }
    }

    // Retrieve User Info from JWT payload
    const breadcrumblist: string[] = history.location.pathname.split('/').filter(item => !item.includes("_") && item.length < 20).map(elem => elem.replace("-", " "));
    breadcrumblist.shift();

    const menuItems: MenuItem[][] = [
        [
            {
                onClick: () => setActiveMenuItem("/dashboard"),
                path: "/dashboard",
                icon: FaTachometerAlt,
                content: "Dashboard",
                membership: true
            },
            {
                onClick: () => setActiveMenuItem("/dashboard/autoships"),
                icon: FaCalendarAlt,
                suffixIcon: FaChevronDown,
                content: "Autoships",
                membership: true,
                exact: false,
                expanded: activeMenuItem.includes("autoships"),
                children: [
                    {
                        path: "/dashboard/autoships/unilevel",
                        icon: FaEllipsisH,
                        content: "Unilevel",
                        membership: true,
                        exact: false
                    },
                    {
                        path: "/dashboard/autoships/placement",
                        icon: FaSitemap,
                        content: "Placement",
                        membership: true,
                        exact: false,
                    }
                ]
            },
            {
                onClick: () => setActiveMenuItem("/dashboard/team-management"),
                path: "/dashboard/team-management",
                icon: FaUsers,
                content: () => <span>Team Management <Badge style={{ opacity: .75 }} color="danger">NEW</Badge></span>,
                membership: true,
            },
            {
                onClick: () => setActiveMenuItem("/dashboard/analyze"),
                path: "/dashboard/analyze",
                icon: FaProjectDiagram,
                content: "Analyze",
                membership: true,
                disabled: true
            }
        ],
        [
            {
                onClick: () => Axios.get("/api/customer-portal").then(url => { window.open(url.data, "_blank") }).catch(e => addToast("Couldn't access Billing data", "Please contact our support for help.", "error")),
                icon: FaFileInvoice,
                content: "Billing",
                membership: true
            },
            {
                onClick: () => setActiveMenuItem("/dashboard/news"),
                path: "/dashboard/news",
                icon: FaNewspaper,
                content: "News",
                membership: undefined
            },
            {
                onClick: () => setActiveMenuItem("/dashboard/contact"),
                path: "/dashboard/contact",
                icon: FaEnvelope,
                content: "Contact",
                membership: undefined
            },
            {
                onClick: () => setActiveMenuItem("/dashboard/help"),
                path: "/dashboard/help",
                icon: FaHandsHelping,
                content: "Help",
                membership: undefined
            }
        ]
    ];

    return <Container fluid className="h-100">
        <Row className="h-100">
            <Col md={2} className="p-0">
                <LoadingNav className="sidebar-wrapper h-100 shadow" loading={getUser.loading || logout.loading}>
                    {
                        getUser.result &&
                        <div className="top-nav">
                            <h1 style={{ fontSize: "1.2rem", fontWeight: "bold" }} className="text-center">IM Leaders Kit Dashboard</h1>
                            <hr />
                            <div className="userdisplay-wrapper d-flex align-items-center">
                                <div><FaUser size={32} /></div>
                                <div className="userdisplay-data" style={{ flex: 1 }}>
                                    <p className="m-0 p-0 name-top">{getUser.result.data.firstname} {getUser.result.data.lastname}</p>
                                    <p className="m-0 p-0 imid-bot">
                                        ID: {getUser.result.data.imAcademyId}
                                    </p>
                                </div>
                                <div>
                                    <UncontrolledDropdown setActiveFromChild >
                                        <DropdownToggle style={{ color: "white", cursor: "pointer" }} tag="a" caret />
                                        <DropdownMenu right className="logout-dropdown">
                                            <DropdownItem onClick={() => logout.execute()} active className="text-center">Logout</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                            <hr />
                            {
                                menuItems.map(menu => <Nav vertical className="menu-section">
                                    {
                                        menu.map(menuItem => <DashboardMenuItem menuItem={menuItem} userData={getUser.result && getUser.result.data} />)
                                    }
                                </Nav>
                                )
                            }
                        </div>
                    }
                    <div className="footer-nav">
                        <hr />
                        © by <a href="https://decoderate.com" target="_blank" className="text-no-decoration">Decoderate e.U.</a> - In corporation with <a href="https://im-teamalpha.com/" target="_blank" className="text-no-decoration">Team ALPHA</a>
                    </div>
                </LoadingNav>
            </Col>
            <Col className="p-0" md={10}>
                <main className="content d-flex flex-column h-100">
                    <div className="breadcrumb-wrapper shadow">
                        <Breadcrumb>
                            {
                                breadcrumblist.map(
                                    (element, i) => {
                                        console.log(breadcrumblist.slice(0, i + 1).join('/'));
                                        return <BreadcrumbItem active={i + 1 === breadcrumblist.length}>
                                            {
                                                i + 1 === breadcrumblist.length
                                                    ? <span style={{ color: "white", cursor: "pointer" }}>{element.toUpperCase()}</span>
                                                    : <Link to={'/' + breadcrumblist.slice(0, i + 1).join('/')}>{element.toUpperCase()}</Link>
                                            }
                                        </BreadcrumbItem>
                                    }
                                )
                            }
                        </Breadcrumb>
                    </div>
                    {props.children}
                </main>
            </Col>

        </Row>
    </Container>
};

export default DashboardLayout;