import React, { FC } from 'react';
import { Spinner } from 'reactstrap';
import '../../../assets/scss/loadingcomponents.scss'

interface LoadingNavProps extends React.HTMLAttributes<HTMLDivElement> {
    loading?: boolean;
}

const LoadingNav: FC<LoadingNavProps> = ({ loading, children, ...rest }) => {
    return loading
        ? <div style={{ display: loading ? "flex" : "none" }} className="loader-overlay"><Spinner size="lg" color="primary" /></div>
        : <nav {...rest}>
            {children}
        </nav>
};

export default LoadingNav;