import React, { FC, useState } from 'react';
import { IconType } from 'react-icons';
import { NavLink, useHistory } from 'react-router-dom';
import { NavItem, Collapse } from 'reactstrap';
import { JsxElement } from 'typescript';

export interface MenuItem {
    path?: string;
    icon: IconType;
    suffixIcon?: IconType;
    content: string | FC;
    membership: boolean | undefined;
    disabled?: boolean;
    exact?: boolean;
    onClick?: () => void;
    children?: MenuItem[];
    expanded?: boolean
}

interface DashboardMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
    menuItem: MenuItem;
    userData: any;
    level?: number;
}

const DashboardMenuItem: FC<DashboardMenuItemProps> = props => {
    const { menuItem, userData, level, ...rest } = props;
    console.log(menuItem.path, level);

    // ADD SUFFIX ICON TO END OF MENU ITEM
    return <>
        <NavItem {...rest}>
            {
                !menuItem.disabled && (menuItem.membership === undefined || (menuItem.membership && userData.membership.subscriptionId))
                    ? menuItem.path
                    && <NavLink onClick={menuItem.onClick} exact={!(menuItem.exact === false)} to={menuItem.path}><menuItem.icon /> {{}.toString.call(menuItem.content) === '[object Function]' && (menuItem.content as any)() || menuItem.content}</NavLink>
                    || <a style={{ cursor: "pointer" }} className="navlink" onClick={menuItem.onClick}><menuItem.icon /> {{}.toString.call(menuItem.content) === '[object Function]' && (menuItem.content as any)() || menuItem.content}</a>
                    : <a href="#" className="disabled-menu-item"><menuItem.icon /> {{}.toString.call(menuItem.content) === '[object Function]' && (menuItem.content as any)() || menuItem.content}</a>
            }
        </NavItem>
        {
            menuItem.children && <Collapse isOpen={menuItem.expanded}>
                {
                    menuItem.children.map(
                        subItem => <DashboardMenuItem style={{
                            marginLeft: Math.min(level || 0, 4) + 1 + "rem",
                        }} menuItem={subItem} userData={userData} level={(level || 0) + 1} className={"sub-" + ((level || 0) + 1) + "-item"} />
                    )
                }
            </Collapse>
        }
    </>
};

export default DashboardMenuItem;