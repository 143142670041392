import React from 'react';
import { Route, Switch } from "react-router";
import DashboardRoute from './routeTypes/DashboardRoute';
import MemberDashboardRoute from './routeTypes/MemberDashboardRoute';
import AutoshipDetailTable from './views/AutoshipDetailTable';
import LoadingDiv from './views/components/LoadingComponents/LoadingDiv';
import './assets/scss/faq.scss';
import ResponseBox from './views/components/ResponseBox';
import { ToastContext, ToastContextProvider } from './contexts/ToastContext';
import UnilevelAutoshipDetails from './views/UnilevelAutoshipDetails';
import TeamManagementPage from './views/TeamManagement';


function App() {
    const LandingPage = React.lazy(() => import('./views/LandingPage'));
    const News = React.lazy(() => import('./views/News'));
    const EmailActivated = React.lazy(() => import('./views/EmailActivated'));
    const DashboardLayout = React.lazy(() => import('./views/layouts/DashboardLayout'));
    const MembershipPacks = React.lazy(() => import('./views/MembershipPacks'));
    const Checkout = React.lazy(() => import('./views/memberships/Checkout'));
    const AutoshipPage = React.lazy(() => import('./views/AutoshipPage'));
    const PlacementAutoshipDetails = React.lazy(() => import('./views/PlacementAutoshipDetails'));
    const ContactPage = React.lazy(() => import('./views/contact/ContactPage'));
    const Help = React.lazy(() => import('./views/help/HelpPage'));
    const Dashboard = React.lazy(() => import('./views/Dashboard'));
    const TeamManagement = React.lazy(() => import('./views/TeamManagement'));
    const AutoshipTreeView = React.lazy(() => import('./views/AutoshipTreeView'));

    return (
        <div className="App">
            <ToastContextProvider>
                <React.Suspense fallback={<LoadingDiv loading={true} className="h-100"></LoadingDiv>}>
                    <Switch>
                        <Route path="/" exact component={LandingPage} />
                        <Route path="/confirm-email/:activationCode" exact component={EmailActivated} />

                        <DashboardRoute path="/dashboard/get-membership" exact component={MembershipPacks} />
                        <DashboardRoute path="/checkout/successful/:sessionId" exact component={Checkout} />
                        <DashboardRoute path="/checkout/canceled" exact component={Checkout} />

                        <MemberDashboardRoute path="/dashboard" exact component={Dashboard} />
                        <MemberDashboardRoute path="/dashboard/news" exact component={News} />

                        <MemberDashboardRoute path="/dashboard/autoships/:type" exact component={AutoshipPage} />
                        <MemberDashboardRoute path="/dashboard/autoships/unilevel/:id" exact component={UnilevelAutoshipDetails} />
                        <MemberDashboardRoute path="/dashboard/autoships/placement/:id" exact component={PlacementAutoshipDetails} />
                        <MemberDashboardRoute path="/dashboard/autoships/:type/:id/tree" exact component={AutoshipTreeView} />
                        <MemberDashboardRoute path="/dashboard/autoships/unilevel/:id/:memberId" exact component={UnilevelAutoshipDetails} />
                        <MemberDashboardRoute path="/dashboard/autoships/placement/:id/:memberId" exact component={PlacementAutoshipDetails} />
                        <MemberDashboardRoute path="/dashboard/analyze" exact component={DashboardLayout} />
                        <MemberDashboardRoute path="/dashboard/contact" exact component={ContactPage} />
                        <MemberDashboardRoute path="/dashboard/team-management" exact component={TeamManagement} />
                        <MemberDashboardRoute path="/dashboard/help" exact component={Help} />
                        <MemberDashboardRoute path="/dashboard/imprint" exact component={DashboardLayout} />
                        <MemberDashboardRoute path="/dashboard/privacy-policy" exact component={DashboardLayout} />
                    </Switch>
                </React.Suspense>
            </ToastContextProvider>
        </div>
    );
}

export default App;
