import React, { FC } from 'react';
import { Spinner } from 'reactstrap';
import '../../../assets/scss/loadingcomponents.scss'

interface LoadingDivProps extends React.HTMLAttributes<HTMLDivElement> {
    loading?: boolean;
    ref?: any;
}

const LoadingDiv: FC<LoadingDivProps> = ({ loading, children, ref, ...rest }) => {
    return loading
        ? <div ref={ref} style={{ display: loading ? "flex" : "none" }} className="loader-overlay"><Spinner size="lg" color="primary" /></div>
        : <div {...rest} ref={ref}>
            {children}
        </div>
};

export default LoadingDiv;