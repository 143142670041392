import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import ResponseBox, { ToastData } from "../views/components/ResponseBox";

export const ToastContext = createContext<any>({});

export const ToastContextProvider = ({ children }: any) => {
  const [toasts, setToasts] = useState<ToastData[]>([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const addedToast = toasts[toasts.length - 1];

      const timer = setTimeout(
        () => setToasts((toasts) => {
          return toasts.splice(1);
        }),
        5000 // Takes the duration value or defaults to 5000ms.
      );
      return () => clearTimeout(timer);
    };
  }, [toasts]);

  const addToasts = useCallback(
    (toast) => setToasts((toasts) => [...toasts, toast]),
    [setToasts]
  );

  return <ToastContext.Provider value={addToasts}>
    {children}
    <ResponseBox toasts={toasts} />
  </ToastContext.Provider>;
};

export const useToastContext = () => {
  return useContext(ToastContext);
};