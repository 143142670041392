import React, { FC, useState } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import '../../assets/scss/response-box.scss';

export interface ToastData {
    title?: string;
    content?: string;
    status: "success" | "error" | "warning" | "information";
};

const ToastResponse = (props: { toast: ToastData }) => {
    const { toast } = props;
    const [hidden, setHidden] = useState(false);
    return <Toast className={`response-toast ${toast.status}-response-toast`} onClick={() => setHidden(true)} hidden={hidden}>
        <ToastHeader>
            {toast.title}
        </ToastHeader>
        <ToastBody>
            {toast.content}
        </ToastBody>
    </Toast>;
};

const ResponseBox: FC<{ toasts: ToastData[] }> = ({ toasts }) => {
    return <div className="response-box-wrapper">
        {
            toasts.map(toast => <ToastResponse toast={toast} />)
        }
    </div>
};

export default ResponseBox;