import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router';
import DashboardMemberLayout from '../views/layouts/DashboardMemberLayout';

const MemberDashboardRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={props => (
        <DashboardMemberLayout>
            {React.createElement(Component as React.FunctionComponent, props as any)}
        </DashboardMemberLayout>
    )} />
}

export default MemberDashboardRoute;