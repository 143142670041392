import Axios from 'axios';
import React, { FC } from 'react';
import { useAsync } from 'react-async-hook';
import { Redirect } from 'react-router';
import LoadingDiv from '../components/LoadingComponents/LoadingDiv';
import DashboardLayout from './DashboardLayout';

const DashboardMemberLayout: FC = ({ children, ...rest }) => {
    const checkMembership = useAsync(() => Axios.get("/api/membership/check").then(response => true).catch(err => false), []);

    return <DashboardLayout {...rest}>
        {checkMembership.result === true
            ? children
            : checkMembership.result === false
                ? <Redirect to="/dashboard/get-membership" />
                : "Loading"}
    </DashboardLayout>
};

export default DashboardMemberLayout;