import Axios from 'axios';
import React, { FC, useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router';
import AutoshipDetailTable from './AutoshipDetailTable';
import LoadingDiv from './components/LoadingComponents/LoadingDiv';

const UnilevelAutoshipDetails: FC = props => {
    const { id, memberId } = useParams() as any;
    const autoship = useAsyncCallback(() => Axios.get("/api/autoship", { params: { id } }));

    useEffect(() => {
        autoship.execute();
    }, []);

    return <LoadingDiv loading={autoship.loading} className="autoships">
        {autoship.result && <AutoshipDetailTable downlineOf={memberId} data={autoship.result.data} type="unilevel" />}
    </LoadingDiv>
};

export default UnilevelAutoshipDetails;