import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router';
import DashboardLayout from '../views/layouts/DashboardLayout';

const DashboardRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={props => (
        <DashboardLayout>
            {React.createElement(Component as React.FunctionComponent, props as any)}
        </DashboardLayout>
    )} />
}

export default DashboardRoute;